@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {
  body {
    @apply bg-gray-600
  }

}
body {
  font-family: 'IBMPIexMono-Regular', monospace, 'IBMPlexMono-Bold' ;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}



